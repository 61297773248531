import styled from "styled-components"
import { muiTheme } from "../../../../../lib/theme"

export const Section = styled.section`
  padding: 40px 0;

  ${muiTheme.breakpoints.down("md")} {
    padding: 40px 0;
    overflow: hidden;
  }
`
