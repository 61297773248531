import React from "react"
import styled from "styled-components"

interface Props {
  style?: any
  className?: string
  "data-sal-duration"?: string
  "data-sal"?: string
  "data-sal-easing"?: string
  "data-sal-delay"?: string
}
const CardWrapper = styled.div`
  text-align: center;
  padding: 45px;
  overflow: hidden;
  box-shadow: ${({ theme }) => theme.boxShadow};
  background-color: white;

  @media (max-width: 360px) {
    padding: 22px;
  }
`
const Card: React.FC<Props> = (props) => {
  return (
    <CardWrapper
      className={props.className}
      style={props.style}
      data-sal-duration={props["data-sal-duration"]}
      data-sal={props["data-sal"]}
      data-sal-easing={props["data-sal-easing"]}
      data-sal-delay={props["data-sal-delay"]}
    >
      {props.children}
    </CardWrapper>
  )
}

export default Card
