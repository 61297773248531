import React from "react"
import Card from "../shared/Card"
import { Grid } from "@material-ui/core"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import webIcon from "../../images/icon/www.svg"
interface Props {
  title: string
  description: string
  linkTitle: string
  linkUrl: string
  imageUrl: string
}

const PartnerHeading = styled.span`
  margin-bottom: 25px;
`

const TextHeader = styled.div`
  text-align: left;
`
const TextDescription = styled.div`
  padding-right: 25px;
  margin: 25px 0;
  text-align: left;

  @media (max-width: 599px) {
    padding-right: 0;
    margin-top: 25px;
  }
`
const TextLink = styled.div`
  display: flex;
  .icon {
    margin-right: 15px;
    max-width: 30px;
  }
`
const ImageWrapp = styled.div`
  width: 100%;
  max-width: 500px;
  img {
    width: 100%;
    margin: 0 auto;
  }
`

const PartnerCard: React.FC<Props> = (props) => {
  const data = useStaticQuery(graphql`
    query ImagePartner {
      image: file(relativePath: { eq: "odtahovka_Zvolen.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <>
      <Card data-sal-duration="650" data-sal="fade" data-sal-easing="ease-in">
        <Grid container className="card_container" alignItems="center">
          <Grid container xs={12} sm={6}>
            <TextHeader>
              <PartnerHeading className="upTitle">Partner</PartnerHeading>
              <h3>{props.title}</h3>
            </TextHeader>

            <TextDescription>
              <p>{props.description}</p>
            </TextDescription>
            <TextLink>
              <div className="icon">
                <img src={webIcon} alt="web ikona" />
              </div>
              <a href={props.linkUrl} target="_blank" rel="noopener noreferrer">
                {props.linkTitle}
              </a>
            </TextLink>
          </Grid>
          <Grid container xs={12} sm={6} justify="center">
            <ImageWrapp>
              <Img fluid={data.image.childImageSharp.fluid} alt={props.title} />
            </ImageWrapp>
          </Grid>
        </Grid>
      </Card>
    </>
  )
}

export default PartnerCard
