import React from "react"
// import { Link } from "gatsby"
import Layout from "../components/shared/Layout/layout"
import SEO from "../components/seo"
import Container from "../components/shared/Layout/components/Container/container"
import styled from "styled-components"
import { Section } from "../components/shared/Layout/components/Section/Section"
import PartnerCard from "../components/PartnerCard/PartnerCard"

const PartnersSection = styled(Section)``
const _Intro = styled.div`
  margin-bottom: 50px;
`
const _Content = styled.div``

const SecondPage = () => (
  <Layout fluid={true}>
    <SEO title="Naši partneri" description="Partneri autoškoly HRON." />
    <PartnersSection>
      <Container fluid={false}>
        <_Intro
          data-sal-duration="650"
          data-sal="fade"
          data-sal-easing="ease-in"
        >
          <span className="upTitle">Partneri</span>
          <h1>Naši partneri</h1>
        </_Intro>
        <_Content>
          <PartnerCard
            title="PATRIK SOS TRANS - Odťahovka Zvolen"
            description="Sme odťahová služba PATRIK SOS TRANS sídliaca vo Zvolene. Svoje
                služby poskytujeme motoristom, ktorí sa ocitli v núdzi. Naším
                zámerom je zabezpečiť im čo najrýchlejšiu a najefektívnejšiu
                technickú pomoc v nepredvídateľných situáciách ako sú dopravné
                nehody, poruchy na motorových vozidlách ,vyslobodenie z
                neprístupného terénu a podzemných garáží. Pomôžeme Vám
                kedykoľvek a kdekoľvek."
            linkTitle="www.odtahovkazvolen.sk"
            linkUrl="http://www.odtahovkazvolen.sk/"
            imageUrl="http://www.odtahovkazvolen.sk/assets/img/2b.jpg"
          ></PartnerCard>
        </_Content>
      </Container>
    </PartnersSection>
  </Layout>
)

export default SecondPage
